<template>
  <nav class="breadcrumbs">
    <component
      :is="link.link ? 'router-link' : 'span'"
      v-for="(link, index) in tree"
      :key="`link_${index}`"
      :to="link.link"
      :class="[
        'breadcrumbs__item',
        { 'breadcrumbs__item--link': link.link },
      ]"
    >
      {{ link.label }}
    </component>
  </nav>
</template>

<script>
import { mapState } from 'vuex';
import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';
import breadcrumb from '@/utils/breadcrumb';

export default {
  name: 'LayoutBreadcrumbs',

  data() {
    return {
      tree: [],
    };
  },

  computed: {
    ...mapState('breadcrumbs', ['breadcrumbs']),

    metaTree() {
      const { params } = this.$route;
      const filteredTree = this.$route.matched.filter((route) => route.path.length > 0);
      const tree = [];
      filteredTree.forEach((route) => {
        let label;
        let link = route.path;
        if (params) {
          Object.keys(params).forEach(((key) => {
            link = link.replace(`:${key}`, params[key]);
          }));
        }

        if (route.meta?.title) {
          label = route.meta.title;
          tree.push(breadcrumb(label, link));
        }
      });

      return [
        breadcrumb('Главная', '/'),
        ...tree,
      ];
    },

    /**
     * Дерево хлебных кроше из стора. Имеет приоритет над хлебными крошками в meta компонента
     */
    staticTree() {
      return this.breadcrumbs.length ? [
        breadcrumb('Главная', '/'),
        ...this.breadcrumbs,
      ] : null;
    },
  },

  watch: {
    // Слежение за роутером (в случае обновления мета-параметров с помощью $router.setMetaOption)
    $route() {
      this.debouncedGenerateTree();
    },

    // Слежение за статическим стором хлебных крошек
    staticTree() {
      this.debouncedGenerateTree();
    },
  },

  created() {
    this.debouncedGenerateTree = debounce(this.generateTree, 100);

    this.generateTree();
  },

  methods: {
    generateTree() {
      const tree = cloneDeep(this.staticTree || this.metaTree);
      const lastTreeItem = tree[tree.length - 1];

      if (lastTreeItem) {
        lastTreeItem.link = null;
      }

      this.tree = tree;
    },
  },
};
</script>

<style lang="scss">
.breadcrumbs {
  display: flex;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  user-select: none;
  color: $color-black;

  &__item {
    cursor: default;
    position: relative;
    margin-right: 15px;
    padding-right: 15px;
    white-space: nowrap;

    &--link {
      color: $color-grayscale-60;
      text-decoration: unset;
      cursor: pointer;
    }

    &:last-child {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 600px;
    }

    &:not(:last-child) {
      &:after {
        content: '';
        border: solid $color-black;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 2px;
        transform: translateY(-50%) rotate(-45deg);
        position: absolute;
        top: 50%;
        right: 1px;
      }

      @include for-size(phone-portrait-down) {
        display: none;
      }
    }
  }
}
</style>
